import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BrandClientDetailsSideMenu: React.FC<any> = (props): JSX.Element => {
  return (
    <>
      <div style={{ marginTop: "0%" }}>
        <Nav>
          <NavLink className="nav-link" to={"/brand-management/" + props.id}>
            Detail
          </NavLink>
        </Nav>
        <Nav>
          <NavLink
            className="nav-link"
            to={`/brand-management/brand-contracts/${props.id}`}
          >
            Brand Contracts
          </NavLink>  
        </Nav>
        <Nav>
          <NavLink
            className="nav-link" 
            to={`/brand-management/brand-surveys/${props.id}`}
          >
            Brand Surveys
          </NavLink>
        </Nav>
        <Nav>
          <NavLink
            className="nav-link"
            to={`/brand-management/brand-properties/${props.id}`}
          >
            Properties
          </NavLink>
        </Nav>
      </div>

      {/* <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="detail" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column sec secondry-menu " style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="detail">Detail</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="brandContracts">Brand Contracts</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="brandSurvey">Brand Surveys</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="properties">Properties</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="detail" >
                                    <div style={navContentStyle}>
                                        <BrandClientDetails />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="brandContracts">
                                    <div style={navContentStyle}>
                                        <BrandContractsList/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="brandSurvey">
                                    <div style={navContentStyle} >
                                            {
                                                enableBrandSurveyDetails ? <BrandSurveyDetailsHome/> : <BrandSurveysList changeBrandSurvey={changeBrandSurvey}/>
                                            }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="properties">
                                    <div style={navContentStyle}>
                                        <BrandProperties/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div> */}
    </>
  );
};

export default BrandClientDetailsSideMenu;
