import React, { useCallback, useEffect } from "react";
import useState from "react-usestateref";
import { Col, Form, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { overlayTriggerDelay } from "../../common/components-funtion";
import {
    navContentStyle,
    navStyle,
    pageHeader,
    tabDivStyle,
    tabInside,
    tableStyles,
} from "../../common/components-style";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useStoreActions, useStoreState } from "../../store/models/model-hook";
import eyeIcon from "../../images/table/eye.svg";
import BrandClientSideMenu from "../brand-management/home/brand-client-side-menu";
import { Typeahead } from "react-bootstrap-typeahead";
import { CURRENT_PAGE } from "../../utils/constants";
import { SetTableOptions } from "../../shared/set-table-options";
import { SpinLoader } from "../../utils/spin-loader";
import moment from "moment";

const RFPList: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [, setSearchKey, searchKeyRef] = useState<String>("");
    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(false);
    const rfpTemplateStatus = "";
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { Templates, RFPTemplateNames, TotalRows, IsLoading } = useStoreState(
        (state) => ({
            Templates: state.rfpTemplateModel.RFPTemplates,
            RFPTemplateNames: state.rfpTemplateModel.RFPTemplateNames,
            TotalRows: state.rfpTemplateModel.TotalRows,
            IsLoading: state.rfpTemplateModel.IsLoading,
        })
    );

    const { getAllRFPTemplates } = useStoreActions((actions) => ({
        getAllRFPTemplates: actions.rfpTemplateModel.getAllRFPTemplates,
    }));

    const getRecords = useCallback(
        (page: number, size = perPage, isFilterRequired: boolean = false, sortfield: string = "", sortorder: string = "") => {
            setLoading(true);
            (async function () {
                await getAllRFPTemplates({
                    security: {
                        APIKey: process.env.REACT_APP_API_KEY as string,
                        User: user.attributes?.email as string,
                    },
                    clientID: id,
                    current: page,
                    pagesize: size,
                    sortfield: sortfield,
                    sortorder: sortorder,
                    Name: searchKeyRef?.current?.replace("/s/g", "") as string,
                    isFilterRequired: isFilterRequired,
                    rfpTemplateStatus: rfpTemplateStatus,
                });
            })().then(function () {
                setLoading(false);
            });
        },
        [getAllRFPTemplates, perPage, searchKeyRef, user.attributes?.email, id]
    );

    useEffect(() => {
        getRecords(CURRENT_PAGE, perPage, true);
    }, [getRecords, perPage]);

    const columns = [
        {
            name: "Client Name",
            selector: (row: any) => row.clientName,
            sortable: true,
            sortField: "CorporateClient",
        },
        {
            name: "Programme",
            selector: (row: any) => row.programme,
        },
        {
            name: "Start Date",
            selector: (row: any) => moment(row.rfpDate, "DD/MM/YYYY").format("DD MMM YYYY"),
            sortable: true,
            sortField: 'RFPDate',
        },
        {
            name: "End Date",
            selector: (row: any) => row.rfp_EndDate ? moment(row.rfp_EndDate, "DD/MM/YYYY").format("DD MMM YYYY"): "",
            sortable: true,
            sortField: 'RFPEndDate',
        },
        {
            name: "Status",
            cell: (row: any) => {
                if (row.rfpStatus === "not_started") {
                    return (
                        <span style={{ color: "rgb(239, 68, 68)", fontWeight: "bold" }}>
                            Not Started
                        </span>
                    );
                } else if (row.rfpStatus === "in_progress") {
                    return (
                        <span style={{ color: "rgba(245, 158, 11)", fontWeight: "bold" }}>
                            In Progress
                        </span>
                    );
                } else if (row.rfpStatus === "completed") {
                    return (
                        <span style={{ color: "rgb(45, 189, 182)", fontWeight: "bold" }}>
                            Completed
                        </span>
                    );
                } else {
                    return (
                        ""
                    );
                }
            },
        },
        {
            name: "Actions",
            button: true,
            cell: (row: any) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id="tooltip-table-top">View</Tooltip>}
                        >
                            <img
                                className="iconSizes"
                                src={eyeIcon}
                                alt=""
                                onClick={() => {
                                    navigate(`/rfp-management/${row.id}`);
                                }}
                            />
                        </OverlayTrigger>
                    </>
                );
            },
        },
    ];

    const handleSort = async (column: any, sortDirection: any) => {
        getRecords(1, perPage, false, column.sortField, sortDirection);
    }

    const handlePageChange = useCallback(
        (page: number) => {
            setCurrentPage(page);
            getRecords(page);
        },
        [getRecords]
    );

    const handlePerRowsChange = useCallback(
        (newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
            getRecords(page);
        },
        [getRecords]
    );

    return (
        <div style={tabDivStyle}>
            <Row>
                <Col sm={2}>
                    <Nav
                        variant="pills"
                        className="flex-column secondry-menu"
                        style={navStyle}
                    >
                        <BrandClientSideMenu />
                    </Nav>
                </Col>
                <Col sm={10}>
                    <SpinLoader isLoading={IsLoading} />
                    <div style={navContentStyle}>
                        <div style={pageHeader}>
                            <h3>RFP Management</h3>
                        </div>
                        {
                            <div style={tabInside}>
                                <Form>
                                    <Row style={{ marginTop: "3%" }}>
                                        <Col md={3}>
                                            <Typeahead
                                                id="rfp-list-filter"
                                                labelKey="name"
                                                clearButton
                                                onChange={(options: any) => {
                                                    if (options && options.length) {
                                                        let rfp = options[0];
                                                        setSearchKey(rfp);
                                                    } else {
                                                        setSearchKey("");
                                                    }

                                                    setCurrentPage(1);
                                                    getRecords(1);
                                                }}

                                                options={RFPTemplateNames}
                                                placeholder="Select a Client"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ marginTop: "1%" }}>
                                    <Col md={12}>
                                        <DataTable
                                            columns={columns}
                                            data={Templates}
                                            striped={true}
                                            customStyles={tableStyles}
                                            theme="mysaTable"
                                            fixedHeader={true}
                                            noDataComponent={<SetTableOptions loading={loading} />}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={TotalRows}
                                            paginationDefaultPage={currentPage}
                                            onSort={handleSort}
                                            sortServer
                                        />
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default RFPList;
